@import 'theme';

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  letter-spacing: 0.5px;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  letter-spacing: -.5px;
  background-color: $black;
}

#root {
  height: 100%;
}

h1 {
  margin: 0;
  font-weight: 300;
  width: 550px;

  b {
    font-weight: 900;
    color: $primary;
  }

  @include media-breakpoint-down(lg) {
    width: 510px;
  }

  @include media-breakpoint-down(md) {
    width: 465px;
  }

  @include media-breakpoint-down(sm) {
    width: 440px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 4rem;
    width: auto;
  }
}

h2 {
  margin: 0;
  font-weight: 400;

  @media (max-width: 375px) {
    font-size: 1.5rem;
  }
}

p {
  margin: 0;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.3;
  max-width: 1000px;

  strong {
    color: $black;
  }

  @media (max-width: 767px) {
    font-size: 2rem;
  }

  @media (max-width: 375px) {
    font-size: 1.75rem;
  }
}

.logo {
  max-width: 300px;

  @media (max-width: 375px) {
    max-width: 250px;
  }
}

.form {
  margin: 1.5rem 0 2.5rem;
  width: 100%;
  max-width: 420px;

  button {
    margin-top: 15px;
    width: 100%;
  }

  .form-control::placeholder {
    color: #848489;
    font-weight: 500;
  }

  .form-message {
    margin-top: 20px;

    a {
      text-decoration: underline;
    }
  }
}

section {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;

  &:nth-child(2) {
    background-color: $secondary;
  }

  &:last-of-type {
    background-color: $primary;

    .btn-primary {
      background-color: $black !important;
      border-color: $black !important;
    }

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;

      &:hover img {
        width: 60px;
      }
    }

    img {
      width: 48px;
    }
  }
}