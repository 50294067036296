@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700,900&display=swap');
@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Regular.woff2') format('woff2'),
    url('./fonts/Metropolis-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-RegularItalic.woff2') format('woff2'),
    url('./fonts/Metropolis-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Bold.woff2') format('woff2'),
    url('./fonts/Metropolis-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-BoldItalic.woff2') format('woff2'),
    url('./fonts/Metropolis-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

$brand-primary: #DD1155;
$brand-secondary: #00D6BA;
$primary: $brand-primary;
$secondary: $brand-secondary;

$enable-responsive-font-sizes: true;

$white: #fefefe;
$black: #080912;
$grey: #7B7B7B;
$body-color: $white;

$link-color: $body-color;

$font-family-base: 'Roboto', Helvetica, Arial, sans-serif;
$font-size-base: 1rem;
$font-weight-bold: 700;
$h1-font-size: $font-size-base * 3.5;
$h3-font-size: 1.5rem;
$h4-font-size: 0.875rem;
$headings-line-height: 1.25;

$input-btn-font-family: 'Metropolis', Helvetica, Arial, sans-serif;
$input-border-color: $white;
$input-border-width: 2px;
$btn-font-weight: $font-weight-bold;

$nav-link-padding-x: 0.625rem;
$navbar-brand-font-size: 0.875rem;

@import '~bootstrap/scss/bootstrap.scss';
